<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editcustomer") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="secondaryProductCode"
              class="form-label required col-sm-10"
              >{{ $t("customercode") }}</label
            >
            <input
              v-model="form.code"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
          <div class="col-sm-5">
            <label
              for="secondaryProductName"
              class="required form-label col-sm-10"
              >{{ $t("customername") }}</label
            >
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="secondaryProductName"
              id="secondaryProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label
              for="selectMainProduct"
              class="form-label col-sm-10 required"
              >{{ $t("businesstype") }}</label
            >
            <div>
              <input
                v-model="form.business_type"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="Cash Van"
                id="flexRadioChecked"
              />
              <label class="form-check-label me-4 ms-4" for="flexRadioChecked"
                >Cash Van</label
              >
              <input
                v-model="form.business_type"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="Pre order"
                id="flexRadioChecked"
              />
              <label class="form-check-label me-4 ms-4" for="flexRadioChecked"
                >Pre order</label
              >
            </div>
          </div>
          <div class="col-sm-5">
            <label
              class="form-check form-check-sm form-check-custom form-check-solid me-5"
            >
              <el-checkbox
                v-model="form.one_time_customer"
                type="checkbox"
                :value="true"
              />
              <span class="form-check-label"> {{ $t("warehousesale") }} </span>
            </label>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-6">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("branchs")
            }}</label>
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.branchId"
            >
              <option v-for="item in branches" :key="item" :value="item.id">
                {{ item.code }} - {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-sm-2">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("isNewCustomer")
            }}</label>
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.isNewCustomer"
            >
              <option :value="true">ลูกค้าใหม่</option>
              <option :value="false">ลูกค้าเก่า</option>
            </select>
          </div>
          <div class="col-sm-2 mb-2">
            <label for="validationTooltip01" class="form-label col-sm-10">{{
              $t("status")
            }}</label>
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.status"
            >
              <option value="normal">normal</option>
              <option value="holding">holding</option>
            </select>
          </div>
        </div>
        <div class="col d-flex justify-content-center mb-4">
          <div class="col-sm-10 text-center">
            <ul class="nav nav-tabs mb-5 fs-5">
              <li class="nav-item nav-item w-md-100px me-0">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                  >{{ $t("generalinformation") }}</a
                >
              </li>
              <li class="nav-item nav-item w-md-100px me-0">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                  >{{ $t("address") }}</a
                >
              </li>
              <!-- <li class="nav-item nav-item w-md-100px me-0">
                <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3"
                  >ที่อยู่ส่งของ</a
                >
              </li> -->
              <!-- <li class="nav-item nav-item w-md-100px me-0">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                  >{{ $t("credit") }}</a
                >
              </li>
              <li class="nav-item nav-item w-md-180px me-0">
                <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_5"
                  >ส่วนลดกลุ่มสินค้า</a
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <div
              class="card-body"
              style="border: solid grey 0.5px; border-radius: 10px"
            >
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade active show"
                  id="kt_tab_pane_1"
                  role="tabpanel"
                >
                  <h1>{{ $t("generalinformation") }}</h1>
                  <div class="col-sm-12 mb-2">
                    <div class="row d-flex">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("pricelist") }}</label
                      >

                      <v-select
                        v-model="form.priceListId"
                        :options="procelists"
                        :reduce="(item) => item.id"
                        label="name"
                      >
                        <template #selected-option-container="{ option }">
                          <div class="vs__selected">
                            {{ option.code }} | {{ option.name }}
                          </div>
                        </template>

                        <template v-slot:option="option">
                          {{ option.code }} | {{ option.name }}
                        </template>
                      </v-select>
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("route") }}</label
                      >

                      <v-select
                        v-model="form.routeId"
                        :options="routes"
                        :reduce="(item) => item.id"
                        label="name"
                      >
                        <template #selected-option-container="{ option }">
                          <div class="vs__selected">
                            {{ option.code }} | {{ option.name }}
                          </div>
                        </template>

                        <template v-slot:option="option">
                          {{ option.code }} | {{ option.name }}
                        </template>
                      </v-select>

                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("storetype") }}</label
                      >
                      <div class="col-sm-12 mb-2">
                        <v-select
                          v-model="form.storeTypeId"
                          :options="store_types"
                          :reduce="(item) => item.id"
                          label="name"
                        >
                          <template #selected-option-container="{ option }">
                            <div class="vs__selected">
                              {{ option.code }} | {{ option.name }}
                            </div>
                          </template>
                          <template v-slot:option="option">
                            {{ option.code }} | {{ option.name }}
                          </template>
                        </v-select>
                      </div>

                      <!-- <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >ภาค</label
                      >
                      <div class="col-sm-12 mb-2">
                        <v-select
                          v-model="form.sectorId"
                          :options="sectors"
                          :reduce="(item) => item.id"
                          label="name"
                        >
                          <template #selected-option-container="{ option }">
                            <div class="vs__selected">
                              {{ option.code }} | {{ option.name }}
                            </div>
                          </template>
                          <template v-slot:option="option">
                            {{ option.code }} | {{ option.name }}
                          </template>
                        </v-select>
                      </div> -->

                      <!-- <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >เขต</label
                      >
                      <div class="col-sm-12 mb-2">
                        <v-select
                          v-model="form.zoneId"
                          :options="zones"
                          :reduce="(item) => item.id"
                          label="name"
                        >
                          <template #selected-option-container="{ option }">
                            <div class="vs__selected">
                              {{ option.code }} | {{ option.name }}
                            </div>
                          </template>
                          <template v-slot:option="option">
                            {{ option.code }} | {{ option.name }}
                          </template>
                        </v-select>
                      </div> -->
                    </div>
                    <!-- <div class="col-sm-12 mb-2">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("discount") }}</label
                      >

                      <v-select
                        v-model="form.discountId"
                        :options="discountslastbill"
                        :reduce="(item) => item.id"
                        label="name"
                      >
                        <template #selected-option-container="{ option }">
                          <div class="vs__selected">
                            {{ option.discount_name }} |
                            {{ option.discount_price }} %
                          </div>
                        </template>
                        <template v-slot:option="option">
                          {{ option.discount_name }} |
                          {{ option.discount_price }} %
                        </template>
                      </v-select>
                    </div> -->
                    <!-- ส่วนลดท้ายบิลเป็น % -->
                    <!-- <div class="col-sm-12">
                      <label
                        for="secondaryProductCode"
                        class="form-label col-sm-10"
                        >{{ $t("boxcode") }}</label
                      >
                      <div class="col-sm-12">
                        <input
                          v-model="form.boxcode"
                          type="text"
                          class="form-control"
                          name="secondaryProductCode"
                          id="secondaryProductCode"
                        />
                        <div class="invalid-feedback text-end">กรุณากรอก</div>
                      </div>
                    </div> -->
                    <!-- <div class="col-sm-12">
                      <div class="row d-flex justify-content-center mb-4">
                        <div class="col-sm-4">
                          <label
                            for="secondaryProductCode"
                            class="form-label col-sm-10"
                            >{{ $t("boxcode") }}</label
                          >
                        </div>
                        <div class="col-sm-8">
                          <label
                            for="secondaryProductCode"
                            class="form-label col-sm-10"
                            >หมายเหตุ</label
                          >
                        </div>
                        <div
                          class="row d-flex justify-content-center mb-4"
                          v-for="(box, index) in boxcodes"
                          :key="box"
                          style="padding-right: 0px; padding-left: 0px"
                        >
                          <div class="col-sm-4">
                            <input
                              v-model="box.boxcode"
                              type="text"
                              class="form-control"
                              name="secondaryProductCode"
                              id="secondaryProductCode"
                            />
                          </div>
                          <div class="col-sm-6">
                            <input
                              v-model="box.description"
                              type="text"
                              class="form-control"
                              name="secondaryProductCode"
                              id="secondaryProductCode"
                            />
                          </div>
                          <div class="col-sm-2" style="text-align-last: right">
                            <button
                              type="button"
                              class="btn btn-sm btn-light"
                              @click="removeBoxCode(index)"
                            >
                              remove
                            </button>
                          </div>
                        </div>
                        <div class="col-sm-12" style="text-align-last: right">
                          <button
                            type="button"
                            class="btn btn-sm btn-light btn-primary"
                            @click="addBoxCode()"
                          >
                            add
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>

                <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                  <h1>{{ $t("address") }}</h1>
                  <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 mb-2">
                      <div class="row d-flex">
                        <label
                          for="secondaryProductCode"
                          class="form-label col-sm-10 required"
                          >{{ $t("address") }}</label
                        >
                        <input
                          v-model="form.address"
                          type="text"
                          class="form-control"
                          name="secondaryProductCode"
                          id="secondaryProductCode"
                          required
                        />
                      </div>
                    </div>

                    <!-- <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10 required"
                        >{{ $t("subdistrict") }}</label
                      >

                      <v-select
                        v-model="form.sub_district"
                        :options="subdistricts"
                        :reduce="(item) => item.id"
                        label="name_in_thai"
                      >
                        <template #selected-option-container="{ option }">
                          <div class="vs__selected">
                            {{ option.name_in_thai }}
                          </div>
                        </template>

                        <template v-slot:option="option">
                          {{ option.name_in_thai }}
                        </template>
                      </v-select>
                    </div> -->
                    <!-- <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10 required"
                        >{{ $t("district") }}</label
                      >
                      <v-select
                        v-model="form.district"
                        :options="districts"
                        :reduce="(item) => item.id"
                        label="name_in_thai"
                      >
                        <template #selected-option-container="{ option }">
                          <div class="vs__selected">
                            {{ option.name_in_thai }}
                          </div>
                        </template>

                        <template v-slot:option="option">
                          {{ option.name_in_thai }}
                        </template>
                      </v-select>
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10 required"
                        >{{ $t("province") }}</label
                      >
                      <v-select
                        v-model="form.province"
                        :options="provinces"
                        :reduce="(item) => item.id"
                        label="name_in_thai"
                      >
                        <template #selected-option-container="{ option }">
                          <div class="vs__selected">
                            {{ option.name_in_thai }}
                          </div>
                        </template>

                        <template v-slot:option="option">
                          {{ option.name_in_thai }}
                        </template>
                      </v-select>
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10 required"
                        >{{ $t("postcode") }}</label
                      >
                      <input
                        v-model="form.zipcode"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div> -->
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("telephone") }}</label
                      >
                      <input
                        v-model="form.tel"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div>

                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10 required"
                        >Line ID</label
                      >
                      <input
                        v-model="form.email"
                        type="email"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10 required"
                        >{{ $t("taxpayer") }}</label
                      >
                      <input
                        v-model="form.tax_id"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        v-mask-number
                        maxlength="13"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("branchtax") }}</label
                      >
                      <input
                        v-model="form.tax_id_branch"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        v-mask-number
                        maxlength="13"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("contact") }}</label
                      >
                      <input
                        v-model="form.contact_person_name_1"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("contact") }}</label
                      >
                      <input
                        v-model="form.contact_person_name_2"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("latitude") }}</label
                      >
                      <input
                        v-model="form.lat"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >{{ $t("longitude") }}</label
                      >
                      <input
                        v-model="form.lng"
                        type="text"
                        class="form-control"
                        id="validationTooltip01"
                        required
                      />
                    </div>
                    <div class="col-sm-12 mt-12">
                      <iframe
                        :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyBYN9eaDDwDAb2LtZFiqXRc1YEMwmLZOGw&q=${form.lat},${form.lng}&zoom=16`"
                        width="820"
                        height="450"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                  <h3>{{ $t("deliveryaddress") }}</h3>
                  <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 mb-2"></div>
                  </div>
                </div>
                <div class="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
                  <h1>{{ $t("credit") }}</h1>
                  <div class="col-sm-12 mb-2">
                    <div class="row d-flex">
                      <div class="col-sm-6">
                        <label
                          for="validationTooltip01"
                          class="form-label col-sm-10 required"
                          >{{ $t("paymenttype") }}
                        </label>
                        <select class="form-select" v-model="form.payment_type">
                          <option>CA</option>
                          <option>CR</option>
                          <option>CQ</option>
                        </select>
                      </div>
                      <div class="col-sm-6">
                        <span style="float: right; color: red; margin-top: 40px"
                          >CA = เงินสด CR = เครดิต CQ = เช็ค</span
                        >
                      </div>
                      <div class="col-sm-6">
                        <label
                          for="validationTooltip01"
                          class="form-label col-sm-10 required"
                          >{{ $t("creditperiod") }}
                        </label>
                        <select
                          class="form-select"
                          v-model="form.credit_period"
                        >
                          <option>7 วัน</option>
                          <option>15 วัน</option>
                          <option>30 วัน</option>
                          <option>45 วัน</option>
                          <option>60 วัน</option>
                        </select>
                      </div>
                      <div class="col-sm-6">
                        <label
                          for="validationTooltip01"
                          class="form-label col-sm-10 required"
                          >{{ $t("limitcredit") }}
                        </label>
                        <input
                          v-model="form.credit_limit"
                          class="form-control"
                          name="secondaryProductCode"
                          id="secondaryProductCode"
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                        />
                      </div>
                      <div class="col-sm-6">
                        <label
                          for="validationTooltip01"
                          class="form-label col-sm-10 required"
                          >{{ $t("balance") }}
                        </label>
                        <input
                          v-model="form.credit_balance"
                          class="form-control"
                          name="secondaryProductCode"
                          id="secondaryProductCode"
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                  <!-- <h3>{{ $t("deliveryaddress") }}</h3> -->
                  <h3>ส่วนลดกลุ่มค้า</h3>
                  <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 mb-2">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >Discount Model</label
                      >

                      <select
                        name=""
                        id=""
                        class="form-select"
                        v-model="form.discountModelId"
                      >
                        <option
                          :value="item.id"
                          v-for="item in discountModels"
                          :key="item"
                        >
                          {{ item.code }} | {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 mb-2">
                      <label
                        for="validationTooltip01"
                        class="form-label col-sm-10"
                        >เลือก District</label
                      >

                      <select
                        name=""
                        id=""
                        class="form-select"
                        v-model="form.districtDiscountId"
                      >
                        <option
                          v-for="item in districtmodels"
                          :key="item"
                          :value="item.id"
                        >
                          {{ item.code }} | {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10 mb-2">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import whApi from "@/api/warehouse/";
import customerAPI from "@/api/customer/";
import promotionAPI from "@/api/promotion/";
import addressApi from "@/api/address/";
import axios from "axios";

export default {
  setup() {
    document.title = "J&N | แก้ไขข้อมูลลูกค้า";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    isSubmit: false,

    form: {
      code: "",
      name: "",
      productGroupId: "",
      payment_type: "CA",
      province: null,
      district: null,
      sub_district: null,
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    mainProduct: [],

    routes: [],
    store_types: [],
    sectors: [],
    zones: [],
    discountslastbill: [],
    provinces: ["Small", "Medium", "Large", "Extra Large"],
    options: [
      { value: "light", label: "Light" },
      { value: "bamboo", label: "Bamboo" },
    ],
    districtmodels: [],
    discountModels: [],
    provinces: [],
    districts: [],
    subdistricts: [],
    boxcodes: [],
  }),

  async created() {
    // await this.getMainProduct();
    await this.getAllBranch();
    await this.getAllPriceList();
    await this.getAllRoute();
    await this.getAllStoreType();
    // await this.getAllSector();
    // await this.getAllZone();
    await this.getAllDiscount();
    await this.getAllDiscountModel();
    await this.getAllDistrictModel();
    await this.getOne();

    await this.getAllProvince();
    // await this.getAllDistrict();
    // await this.getAllSubdistrict();
  },
  watch: {
    "form.province"(val) {
      console.log("formformform", val);
      this.getAllDistrict(val);
    },
    "form.district"(val) {
      console.log("formsubdistrict", val);
      this.getAllSubdistrict(val);
    },

    // "form.districtId"(val){
    //   console.log('formsubdistrict',val);
    //   this.getAllSubdistrict(val);
    // },
  },

  methods: {
    async getAllProvince() {
      // const response = await axios.get(`https://thai-drug-service-temp.yuzudigital.com/locations/findAllProvinces`);
      const response = await addressApi.province.getAllProvince();
      console.log("provinces", response.data);
      this.provinces = response.data;
    },
    async getAllDistrict(id) {
      const response = await addressApi.province.getAllDistrict(id);
      console.log("district", response.data);
      this.districts = response.data;
    },
    async getAllSubdistrict(id) {
      const response = await addressApi.province.getAllSubdistrict(id);
      console.log("subdistrict", response.data);
      this.subdistricts = response.data;
    },

    async getAllDiscountModel() {
      let responseData = [];
      try {
        responseData = await promotionAPI.discount.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.discountModels = responseData.data;
      }
    },
    async getAllDistrictModel() {
      let responseData = [];
      try {
        responseData = await promotionAPI.district.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.districtmodels = responseData.data;
      }
    },
    async getAllDiscount() {
      let responseData = [];
      try {
        responseData = await customerAPI.discount.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.discountslastbill = responseData.data;
      }
    },
    // async getAllZone() {
    //   let responseData = [];
    //   try {
    //     responseData = await customerAPI.zone.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.zones = responseData.data;
    //   }
    // },
    // async getAllSector() {
    //   let responseData = [];
    //   try {
    //     responseData = await customerAPI.sector.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.sectors = responseData.data;
    //   }
    // },
    async getAllStoreType() {
      let responseData = [];
      try {
        responseData = await customerAPI.store_type.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.store_types = responseData.data;
      }
    },
    async getAllPriceList(warehouseId) {
      let responseData = [];
      try {
        responseData = await whApi.pricelist.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.procelists = responseData.data;
      }
    },
    async getAllRoute() {
      let responseData = [];
      try {
        responseData = await customerAPI.route.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.routes = responseData.data;
      }
    },
    async getAllBranch() {
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branches = responseData.data;
      }
    },

    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await customerAPI.customer.getOne(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
          province: getOneResponse.data.province * 1,
          district: getOneResponse.data.district * 1,
          sub_district: getOneResponse.data.sub_district * 1,
          // productGroupId: getOneResponse.data.mainProductGroupId,
        };
        this.boxcodes = this.form.boxs;
        if (this.boxcodes.length <= 0) {
          this.addBoxCode();
        }

        this.loading = false;
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updateResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();
          this.isSubmit = true;

          this.form = {
            ...this.form,
            mainProductGroupId: this.form.productGroupId,
            boxs: this.boxcodes,
            // status: this.form.status === "2" ? "0" : this.form.status,
            companyId: parseInt(localStorage.getItem("companyId")),
          };
          if (this.isValid) {
            this.loadingUpdate = true;
            updateResponse = await customerAPI.customer.update(
              this.$route.query.id,
              this.form
            );
            if (updateResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: `แก้ไขสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/CUSTOMER/index",
                  query: {},
                });
              });
              this.loadingUpdate = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${updateResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingUpdate = false;
            }
          }
        }
      });
    },

    checkFormIsEmpty() {
      !this.form.code
        ? (this.isValid = false)
        : !this.form.name
        ? (this.isValid = false)
        : !this.form.business_type
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/CUSTOMER/index",
        query: {},
      });
    },
    addBoxCode() {
      this.boxcodes.push({ code: "", description: "" });
    },
    removeBoxCode(index) {
      if (this.boxcodes.length > 1) {
        this.boxcodes.splice(index, 1);
      }
    },
  },
};
</script>
